import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["template", "container"];

  addInput(e) {
    if (e) e.preventDefault();

    const clone = document.importNode(this.templateTarget.content, true);
    this.containerTarget.appendChild(clone);
  }
}
