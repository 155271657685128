import { Controller } from "stimulus";
import Sortable from "sortablejs/modular/sortable.core.esm.js";

export default class extends Controller {
  initialize() {
    Sortable.create(this.element, {
      handle: ".handle",
      draggable: ".sortable-item",
      animation: 150,
      onUpdate: this.onUpdate.bind(this),
    });
  }

  onUpdate(e) {
    const updatedElement = e.to.children[e.newDraggableIndex];
    updatedElement.dispatchEvent(
      new CustomEvent("position-updated", {
        detail: {
          position: e.newDraggableIndex,
        },
      })
    );
  }

  preventDefault(e) {
    e.preventDefault();
  }
}
