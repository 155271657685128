import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    changed: Boolean,
  };

  leavingPage(event) {
    if (this.isFormChanged()) {
      if (event.type == "turbo:before-visit") {
        if (
          !window.confirm(
            "Les modifications ne seront pas enregistrées. Quitter quand même ?"
          )
        ) {
          event.preventDefault();
        }
      } else {
        event.returnValue = true;
        return event.returnValue;
      }
    }
  }

  formIsChanged(e) {
    this.changedValue = e.detail?.editMode;
  }

  allowFormSubmission(e) {
    this.changedValue = false;
  }

  isFormChanged() {
    return this.changedValue == true;
  }
}
