import { Controller } from "stimulus";
import L from "leaflet";

export default class extends Controller {
  static targets = ["tooltip"];

  static values = { alwaysOn: Boolean };

  connect() {
    this.mapController = this.application.getControllerForElementAndIdentifier(
      this.element,
      "map"
    );
    this.map = this.mapController.map;

    this.rectangle = L.marker(this.mapController.dofusToLeafletCoords(0, 0), {
      interactive: false,
      icon: L.divIcon({
        className: "rectangleOnMap",
        iconAnchor: [0, 0],
        iconSize: this.mapController.getRectangleOnMapSize(),
      }),
    });

    if (this.alwaysOnValue) {
      this.setMapCoordinates(
        this.mapController.posXValue,
        this.mapController.posYValue
      );
      this.rectangle.addTo(this.map);
    }

    this.map.on("mousemove", this.mouseMoveOnMap.bind(this));
    this.map.on("mouseout", this.mouseOutOfMap.bind(this));
    this.map.on("zoomanim", this.zoomChangeOnMap.bind(this));
    this.map.on("zoomend", this.zoomChangeOnMap.bind(this));
  }

  zoomChangeOnMap(e) {
    var iconRectangle = this.rectangle.options.icon;
    iconRectangle.options.iconSize = this.mapController.getRectangleOnMapSize();
    this.rectangle.setIcon(iconRectangle);
  }

  setMapCoordinates(x, y) {
    if (this.hasTooltipTarget)
      this.tooltipTarget.innerHTML = `${
        this.subAreaName ? `<div class="me-2">${this.subAreaName}</div>` : ""
      }<div>[ ${x}, ${y} ]</div>`;
    this.rectangle.setLatLng(this.mapController.dofusToLeafletCoords(x, y));
  }

  mouseMoveOnMap(e) {
    this.map.invalidateSize(); // FIXME: invalidate size only when map being resized, not everytime
    const coords = this.mapController.leafletToDofusCoords(
      e.latlng.lat,
      e.latlng.lng
    );
    const x = Math.floor(coords[0]);
    const y = Math.floor(coords[1]);
    this.setMapCoordinates(x, y);
  }

  mouseOutOfMap(e) {
    if (this.alwaysOnValue) {
      this.setMapCoordinates(
        this.mapController.posXValue,
        this.mapController.posYValue
      );
    }
  }

  subAreaChanged(e) {
    this.subAreaName = e?.detail?.name;

    if (!this.subAreaName && this.map.hasLayer(this.rectangle)) {
      this.rectangle.removeFrom(this.map);
      if (this.hasTooltipTarget)
        this.tooltipTarget.classList.remove("sub-area");
    } else if (this.subAreaName && !this.map.hasLayer(this.rectangle)) {
      this.rectangle.addTo(this.map);
      if (this.hasTooltipTarget) this.tooltipTarget.classList.add("sub-area");
    }
  }

  reset() {
    this.subAreaName = null;
    if (this.hasTooltipTarget) this.tooltipTarget.innerHTML = null;
    if (this.map.hasLayer(this.rectangle)) this.rectangle.removeFrom(this.map);
    this.zoomChangeOnMap();
  }
}
