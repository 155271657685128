import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "replace", "frame"];
  static values = {
    url: String,
  };

  submit(event) {
    event.preventDefault();

    this.frameTarget.src = this.formUrl();
  }

  visit(event) {
    event.preventDefault();

    Turbo.visit(this.formUrl());
  }

  formUrl() {
    return `${this.formPath()}?${this.formParams()}`;
  }

  formPath() {
    let path = this.urlValue;
    this.replaceTargets.forEach((replaceTarget) => {
      path = path.replace(`:${replaceTarget.id}`, replaceTarget.value);
    });
    return path;
  }

  formParams() {
    let searchParams = new URLSearchParams();
    this.inputTargets.forEach((inputTarget) => {
      searchParams.set(inputTarget.name, inputTarget.value);
    });
    return searchParams;
  }
}
