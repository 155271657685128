import { Controller } from "stimulus";
import { Editor } from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import Reference from "../editor_extensions/reference";
export default class extends Controller {
  static targets = ["element", "htmlPreview", "jsonPreview", "contentInput"];

  static values = {
    placeholder: String,
  };

  initialize() {
    this.boundHandleEditorUpdate = this.handleEditorUpdate.bind(this);
  }

  connect() {
    this.editor = new Editor({
      element: this.elementTarget,
      editorProps: {
        attributes: {
          class: "mx-2 mt-2 pt-2",
        },
      },
      extensions: [
        StarterKit,
        Placeholder.configure({
          placeholder: this.placeholderValue || "Écrire un commentaire...",
        }),
        Reference,
      ],
      content: this.contentInputTarget.value
        ? JSON.parse(this.contentInputTarget.value)
        : "",
    });

    this.editor.on("update", this.boundHandleEditorUpdate);
    this.handleEditorUpdate();
  }

  disconnect() {
    this.editor.off("update", this.boundHandleEditorUpdate);
  }

  handleEditorUpdate(event) {
    // console.log("onUpdateEditor");
    // console.log(event);

    if (this.hasHtmlPreviewTarget) {
      this.htmlPreviewTarget.textContent = this.editor.getHTML();
      this.jsonPreviewTarget.textContent = JSON.stringify(
        this.editor.getJSON(),
        null,
        2
      );
    }
    this.contentInputTarget.value = JSON.stringify(this.editor.getJSON());
    this.contentInputTarget.dispatchEvent(
      new CustomEvent("change", {
        detail: { editMode: this.editor.getText().length > 0 },
      })
    );
  }

  bold() {
    this.editor.chain().focus().toggleBold().run();
  }

  italic() {
    this.editor.chain().focus().toggleItalic().run();
  }

  addReferenceEvent(event) {
    this.addReference(event.detail.reference_id);
  }

  addReference(id) {
    this.editor
      .chain()
      .focus()
      .insertContent({
        type: "reference",
        attrs: {
          reference_id: id,
        },
      })
      .insertContent(" ")
      .run();
  }

  setDemoContent() {
    this.editor
      .chain()
      .focus()
      .setContent(
        `<p>Voici un commentaire de démo avec du <b>texte riche</b>. Même si certains <em>décorateurs de texte</em> sont <code>moins utiles</code> dans ce contexte, ça laisse la liberté de s'exprimer 🙂</p><p>Tu peux conseiller les gens d'aller voir <reference reference_id="15513"></reference> sur la map <reference reference_id="7287"></reference> ou encore insérer d'autres contenus riches : <reference reference_id="18974"></reference> <b><reference reference_id="19064"></reference></b> <em><reference reference_id="40328"></reference></em> via les icônes ci-dessous.</p><p>N'hésite pas à utiliser <b>Ctrl + Z</b> et <b>Ctrl + Shift + Z</b> comme dans tout éditeur de texte récent pour revenir en arrière.</p><p>Ah et bonne nouvelle, si la ressource que tu as inséré dans ton commentaire <b>change ou est supprimée du jeu</b> quelques mois plus tard, la personne lisant ton commentaire sera au courant ! 😎</p>`
      )
      .run();

    // temporary fix because update event is not triggered
    this.handleEditorUpdate();
  }
}
