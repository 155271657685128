import { Controller } from "stimulus";
import { mapIcons } from "./map_controller";
import L from "leaflet";

export default class extends Controller {
  static targets = ["frame"];

  initialize() {
    this.mapController = this.application.getControllerForElementAndIdentifier(
      this.element,
      "map"
    );
    this.map = this.mapController.map;

    this.leftPanelController =
      this.application.getControllerForElementAndIdentifier(
        this.element,
        "map-left-panel"
      );
    this.directionsController =
      this.application.getControllerForElementAndIdentifier(
        this.element,
        "map-directions"
      );

    this.disabledMode = true;
    this.map.on("click", this.onClick.bind(this));
  }

  updateSelectionMarker() {
    const latlng = this.mapController.dofusToLeafletCoords(
      this.posXValue + 0.5,
      this.posYValue + 0.5
    );
    if (!this.selectionMarker) {
      this.selectionMarker = L.marker(latlng, {
        icon: mapIcons.hint,
      });
      this.selectionMarker.addTo(this.map);
    } else {
      this.selectionMarker.setLatLng(latlng);
    }
  }

  onClick(e) {
    if (this.disabledMode) this.reset();
    else this.fetchPreview(e);
  }

  fetchPreview(e) {
    const coords = this.mapController.leafletToDofusCoords(
      e.latlng.lat,
      e.latlng.lng
    );
    this.posXValue = Math.floor(coords[0]);
    this.posYValue = Math.floor(coords[1]);

    this.updateSelectionMarker();

    this.replaceFrame(
      `/fr/maps/preview/${this.mapController.config.id}/${this.posXValue}/${this.posYValue}`
    );
  }

  replaceFrame(url) {
    this.frameTarget.innerHTML = "";
    this.frameTarget.src = url;
    this.frameTarget.classList.remove("hidden");
    this.leftPanelController.reset();
    this.directionsController.reset();
  }

  reset() {
    if (this.selectionMarker) {
      this.selectionMarker.remove();
      this.selectionMarker = null;
    }
    this.hide();
  }

  hide() {
    this.frameTarget.classList.add("hidden");
  }

  subAreaChanged(e) {
    const { name } = e.detail;

    this.disabledMode = !name;
  }
}
