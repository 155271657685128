import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["ladder"];

  select(event) {
    this.ladderTargets.forEach((ladder) => {
      const element = ladder.querySelector(".black-filter");

      if (ladder.contains(event.target)) {
        element.classList.remove("unselected-black-filter");
      } else {
        element.classList.add("unselected-black-filter");
      }
    });
  }
}
