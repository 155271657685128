import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form", "position"];

  update(e) {
    this.positionTarget.value = parseInt(e.detail.position) + 1;
    this.formTarget.requestSubmit();
  }
}
