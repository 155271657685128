import { Node, mergeAttributes } from "@tiptap/core";

export default Node.create({
  name: "reference",

  group: "inline",
  inline: true,

  atom: true,

  addAttributes() {
    return {
      reference_id: {
        default: 485,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "reference",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["reference", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ({ node }) => {
      const referenceId = node.attrs.reference_id;

      const dom = document.createElement("span");

      // TODO: setup a timeout for `fetch` and only display html on response or error (to avoid the dirty flash rendering of the temporary message)
      //dom.innerHTML = `<b>[Référence #${referenceId}]</b>`;

      fetch(`/fr/encyclopedia/previews/references/${referenceId}`)
        .then((response) => response.text())
        .then((html) => {
          dom.innerHTML = html;
        })
        .catch((error) => {
          // Fallback if the network request failed
          console.error(`Request failed loading preview. ${error}`);
        });

      return {
        dom,
      };
    };
  },
});
