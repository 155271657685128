import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["frame", "child"];

  reset(e) {
    if (e?.detail?.keepPanel) {
      this.childTarget.dispatchEvent(
        new CustomEvent("world-changed", {
          detail: e.detail,
        })
      );
    } else {
      this.frameTarget.src = null;
      this.frameTarget.innerHTML = "";
    }
  }
}
