import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["npc", "map", "url"];

  updateNpcQueryParam() {
    this.searchPopoverController.urlValue = `/fr/encyclopedia/searchs/npc_answers?npc_id=${this.npcTarget.value}`;
  }

  get searchPopoverController() {
    return this.application.getControllerForElementAndIdentifier(
      this.urlTarget,
      "search-popover"
    );
  }
}
