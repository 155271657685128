import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "display"];

  connect() {
    document.addEventListener("keydown", this.onKeyDown.bind(this));
  }

  onKeyDown(e) {
    const key = e.key.toLowerCase();
    if (key.length === 1 && ((key >= "a" && key <= "z") || key === " ")) {
      this.inputTarget.value += key;
      this.displayTarget.value += key;
    } else if (e.key === "Backspace") {
      this.inputTarget.value = this.inputTarget.value.slice(0, -1);
      this.displayTarget.value = this.displayTarget.value.slice(0, -1);
    }
  }
}
