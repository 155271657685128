import { Controller } from "stimulus";
import tippy, { followCursor } from "tippy.js";

// help : https://chrislabarge.com/posts/stimulus-popup/

export default class extends Controller {
  static values = {
    contentUrl: String,
    content: String,
    contentAlt: String,
    fixed: Boolean,
    placement: String,
    theme: String,
  };

  static targets = ["contentTemplate"];

  initialize() {
    this.boundHandleTooltipShow = this.handleTooltipShow.bind(this);

    let content = this.contentValue;
    if (this.hasContentTemplateTarget) {
      content = this.contentTemplateTarget.innerHTML;
    }

    this.tipper = tippy(this.element, {
      theme: this.themeValue || "custom",
      content: content,
      arrow: false,
      touch: false,
      followCursor: !this.fixedValue,
      placement: this.placementValue || "top",
      allowHTML: true,
      onCreate(instance) {
        instance._isOpened = false;
      },
      onShow: this.boundHandleTooltipShow,
      plugins: [followCursor],
      zIndex: 99999,
      maxWidth: 500,
      popperOptions: {
        strategy: "fixed",
      },
    });
  }

  alt(e) {
    if (!this.hasContentAltValue) return;

    e.preventDefault();

    this.tipper.setContent(this.contentAltValue);
    this.tipper.show();
    setTimeout(this.endAlt.bind(this), 5000);
  }

  endAlt() {
    this.tipper.setContent(this.contentValue);
  }

  handleTooltipShow(instance) {
    if (instance._isOpened) {
      return;
    }

    instance._isOpened = true;

    if (this.contentValue || this.hasContentTemplateTarget) {
      return;
    }

    if (!this.contentUrlValue) {
      instance.setContent(`Invalid tooltip`);
      return;
    }

    // TODO: maybe use turbo frame ? https://discuss.hotwired.dev/t/triggering-turbo-frame-with-js/1622/37
    fetch(this.contentUrlValue)
      .then((response) => {
        if (!response.ok) {
          let err = new Error("HTTP status code: " + response.status);
          err.response = response;
          err.status = response.status;
          throw err;
        }
        return response.text();
      })
      .then((html) => {
        instance.setContent(html);
      })
      .catch((error) => {
        // Fallback if the network request failed
        console.error(`Request failed. ${error}`);

        instance.setContent(`Erreur ${error.status}`);
      });
  }
}
