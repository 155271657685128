import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["direction"];
  static values = {
    data: Object,
    selectedId: Number,
  };

  connect() {
    this.directionTargets.forEach((directionTarget) => {
      if (directionTarget.classList.contains("selected")) {
        this.selectedTarget = directionTarget;
        this.selectedTargetChanged();
      }
    });
    if (this.selectedIdValue)
      this.dispatchMapDestinations(this.selectedIdValue);
  }

  selectDirection(event) {
    let selectionTarget;

    this.directionTargets.forEach((directionTarget) => {
      if (directionTarget.contains(event.target))
        selectionTarget = directionTarget;
    });

    if (selectionTarget != this.selectedTarget) {
      this.selectedTarget.classList.remove("selected");
      selectionTarget.classList.add("selected");
      this.selectedTarget = selectionTarget;

      this.selectedTargetChanged();
    }
  }

  selectedTargetChanged() {
    if (!this.selectedTarget) return;

    this.dispatchMapDestinations(
      Number(this.selectedTarget.dataset.directionId)
    );
  }

  worldChanged(e) {
    if (e.detail?.keepPanel)
      this.dispatchMapDestinations(
        this.selectedIdValue || Number(this.selectedTarget.dataset.directionId)
      );
  }

  dispatchMapDestinations(selectedId) {
    this.element.dispatchEvent(
      new CustomEvent("map-destinations-changed", {
        detail: {
          data: this.dataValue,
          selectedId,
        },
        bubbles: true,
      })
    );
  }
}
