import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    start: Number,
    duration: Number,
    lostUrl: String,
  };

  connect() {
    this.startTime = Math.floor(this.startValue * 1000);

    this.timerInterval = setInterval(this.printTime.bind(this), 1000);
    this.printTime();
  }

  disconnect() {
    clearInterval(this.timerInterval);
  }

  printTime() {
    const elapsedTime = Date.now() - this.startTime;
    const remainingTime = this.durationValue * 1000 - elapsedTime;

    if (Math.floor(remainingTime / 1000) < 0) {
      clearInterval(this.timerInterval);

      Turbo.visit(this.lostUrlValue);
    } else {
      this.element.innerHTML = this.timeToString(remainingTime);
    }
  }

  timeToString(time) {
    let diffInHrs = time / 3600000;
    let hh = Math.floor(diffInHrs);

    let diffInMin = (diffInHrs - hh) * 60;
    let mm = Math.floor(diffInMin);

    let diffInSec = (diffInMin - mm) * 60;
    let ss = Math.floor(diffInSec);

    let formattedMM = mm.toString().padStart(2, "0");
    let formattedSS = ss.toString().padStart(2, "0");

    let cssClass = "";
    if (!hh && !mm && ss <= 20) {
      cssClass = "count-down-alert";
    }
    return `<span class="${cssClass}">${formattedMM}:${formattedSS}</span>`;
  }
}
