import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button", "input"];
  static values = {
    successDuration: Number,
    text: String,
  };

  connect() {
    if (!this.hasButtonTarget) return;

    this.originalText = this.buttonTarget.innerText;
  }

  copy(event) {
    event.preventDefault();

    if (this.hasInputTarget)
      navigator.clipboard.writeText(this.inputTarget.value);
    if (this.textValue) navigator.clipboard.writeText(this.textValue);

    this.copied();
  }

  copied() {
    if (!this.hasButtonTarget) return;

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.buttonTarget.innerText = this.data.get("successContent");

    this.timeout = setTimeout(() => {
      this.buttonTarget.innerText = this.originalText;
    }, this.successDurationValue || 2000);
  }
}
