import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["world", "counter", "name", "icon", "loader"];

  connect() {
    this.mapController = this.application.getControllerForElementAndIdentifier(
      this.element,
      "map"
    );
    this.map = this.mapController.map;
    this.mapInteractiveItemsController =
      this.application.getControllerForElementAndIdentifier(
        this.element,
        "map-interactive-items"
      );
    this.interactives = {};
    this.frameLoad = false;
  }

  loadInteractives(e) {
    if (!this.frameLoad && e.target.id === "index_search_world_maps") {
      this.mapInteractiveWorld((world) =>
        Object.keys(world).forEach((itemId) => this.addInteractives(itemId))
      );
    }
  }
  stop(e) {
    e.stopPropagation();
  }

  interactiveSelection(e) {
    const { itemId, itemData, selected } = e.detail;
    if (selected) {
      itemData.maps.forEach((map) => {
        const world = this.interactives[map.w] || {};
        world[itemId] = {
          img: itemData.img,
          name: itemData.name,
          n: (world[itemId]?.n || 0) + map.n,
        };
        this.interactives[map.w] = world;
      });
    } else {
      this.mapInteractiveWorld((world) => {
        if (world[itemId]) {
          world[itemId].n = 0;
          if (world[itemId].div) world[itemId].div.remove();
        }
      });
    }

    this.updateCounter();

    if (selected) this.addInteractives(itemId);
  }

  mapInteractiveWorld(fn) {
    Object.keys(this.interactives).map((k) => {
      fn(this.interactives[k]);
    });
  }

  updateCounter() {
    const qnt = Object.keys(this.interactives).reduce((res, k) => {
      if (this.mapController.config.id === parseInt(k)) return res;
      const world = this.interactives[k];
      return res + Object.keys(world).reduce((res, k) => res + world[k].n, 0);
    }, 0);
    this.counterTarget.innerHTML = qnt > 0 ? `+${qnt}` : "";
  }

  addInteractives(itemId) {
    this.worldTargets.forEach((worldTarget) => {
      const worldId = worldTarget.dataset.id;
      const world = this.interactives[worldId]?.[itemId];
      if (world && !world.div && world.n > 0) {
        world.div = document.createElement("div");
        world.div.classList = "d-flex flex-row align-items-center no-margin";
        world.div.innerHTML = `<span class="game-img-container img-sm me-1"><img src="${world.img}" class="img-100"><small class="quantity">${world.n}</small></span>`;
        worldTarget.appendChild(world.div);
      }
    });
  }

  requestSwitch(event) {
    event.preventDefault();

    this.iconTarget.classList.add("hidden");
    this.loaderTarget.classList.remove("hidden");

    fetch(`/fr/world_maps/${event.detail.id}.json`)
      .then((response) => response.json())
      .then(this.applySwitch.bind(this, event.detail));
  }

  applySwitch(options, world) {
    this.nameTarget.innerHTML = world.name;

    this.mapController.switchWorldMap(world.map_config);

    this.element.dispatchEvent(
      new CustomEvent("world-changed", {
        detail: options,
      })
    );
    this.updateCounter();
    this.iconTarget.classList.remove("hidden");
    this.loaderTarget.classList.add("hidden");
  }

  panelSwitch(event) {
    event.preventDefault();

    this.iconTarget.classList.add("hidden");
    this.loaderTarget.classList.remove("hidden");

    this.nameTarget.innerHTML = event.detail.name;

    this.mapController.switchWorldMap(JSON.parse(event.detail.config));
    this.map.setView(
      this.mapController.dofusToLeafletCoords(
        ...this.mapController.config.center
      ),
      this.mapController.config.zoom,
      { animation: true }
    );
    this.element.dispatchEvent(new CustomEvent("world-changed"));

    this.updateCounter();
    this.iconTarget.classList.remove("hidden");
    this.loaderTarget.classList.add("hidden");
  }
}
