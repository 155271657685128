import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  filterList(event) {
    var searchParams = new URLSearchParams(document.location.search);
    if (event.target.value.length < 1) {
      searchParams.delete(event.target.name);
    } else {
      searchParams.set(event.target.name, event.target.value);
    }
    searchParams.delete("page");

    var href = document.location.href;
    var end = href.indexOf("?") < 0 ? href.length : href.indexOf("?");

    if (searchParams.toString()) {
      href = `${href.substring(0, end)}?${searchParams.toString()}`;
    } else {
      href = href.substring(0, end);
    }

    this.updateList(href);
  }

  updateList(href) {
    fetch(href, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
      .then((_) => history.replaceState(history.state, "", href));
  }
}
