import { Controller } from "stimulus";
import L from "leaflet";
import FlagImage from "../images/flag.png";
import QuestStartImage from "../images/quest_start.png";
import ZaapImage from "../images/zaap.png";
import DestinationImage from "../images/destination.png";
import MoonImage from "../images/moon.png";

export const mapIcons = {
  hint: L.icon({
    iconUrl: FlagImage,
    iconSize: [30, 30],
    iconAnchor: [15, 30],
  }),
  quest_start: L.icon({
    iconUrl: QuestStartImage,
    iconSize: [30, 30],
    iconAnchor: [15, 20],
  }),
  zaap: L.icon({
    iconUrl: ZaapImage,
    iconSize: [30, 30],
    iconAnchor: [15, 20],
  }),
  moon: L.icon({
    iconUrl: MoonImage,
    iconSize: [40, 40],
    iconAnchor: [20, 20],
  }),
  destination: L.icon({
    iconUrl: DestinationImage,
    iconSize: [40, 40],
    iconAnchor: [8, 30],
  }),
};
export default class extends Controller {
  static targets = ["container", "input"];

  static values = {
    posX: Number,
    posY: Number,
    layers: Object,
  };

  connect() {
    this.config = this.layersValue.world_map;

    const centerPosition = this.dofusToLeafletCoords(
      this.posXValue + 0.5,
      this.posYValue + 0.5
    );

    this.map = L.map(this.containerTarget, {
      crs: L.CRS.Simple,
      center: centerPosition,
      zoom: this.config.zoom,
      maxZoom: this.config.max_zoom,
      minZoom: this.config.min_zoom,
      attributionControl: false,
      // preferCanvas: true,
    });

    this.setupTileLayer();

    this.markers = [];
    for (const marker of this.layersValue.markers || []) {
      const markerPosition = this.dofusToLeafletCoords(
        marker.pos_x + 0.5,
        marker.pos_y + 0.5
      );

      this.markers.push(
        L.marker(markerPosition, { icon: mapIcons[marker.icon] }).addTo(
          this.map
        )
      );
    }

    if (this.layersValue.draw_line) {
      const polyline = L.polyline(
        this.markers.map((marker) => marker.getLatLng()),
        { color: "#5B65EA" }
      ).addTo(this.map);

      this.map.fitBounds(polyline.getBounds().pad(0.3));
    }

    if (this.hasInputTarget) this.map.on("click", this.clickOnMap.bind(this));

    // L.GridLayer.DebugCoords = L.GridLayer.extend({
    //   createTile: function (coords) {
    //     var tile = document.createElement("div");
    //     tile.innerHTML = [coords.x, coords.y, coords.z].join(", ");
    //     tile.style.outline = "1px solid red";
    //     return tile;
    //   },
    // });

    // L.gridLayer.debugCoords = function (opts) {
    //   return new L.GridLayer.DebugCoords(opts);
    // };

    // this.map.addLayer(L.gridLayer.debugCoords());
  }

  setupTileLayer() {
    const bounds = L.latLngBounds(
      L.latLng(this.dofusToLeafletCoords(...this.config.bounds[0])),
      L.latLng(this.dofusToLeafletCoords(...this.config.bounds[1]))
    );

    if (this.tileLayer) this.tileLayer.remove();
    this.tileLayer = L.tileLayer(this.tileUrl(), {
      bounds: bounds,
      maxZoom: this.config.max_zoom,
      noWrap: true,
    }).addTo(this.map);
  }

  tileUrl() {
    if (this.config.dir) {
      return `${process.env.VERSIONNED_STATIC_ASSETS_PATH}/maps/tiles/${this.config.dir}/{z}-{x}-{y}.jpg`;
    } else {
      return `${process.env.STATIC_ASSETS_PATH}/maps/tiles/${this.config.id}/{z}-{x}-{y}.jpg`;
    }
  }

  switchWorldMap(config) {
    this.config = config;

    this.setupTileLayer();

    this.map.setMinZoom(this.config.min_zoom);
    this.map.setMaxZoom(this.config.max_zoom);
  }

  getRectangleOnMapSize() {
    var zoom = this.map.getZoom();
    return [
      Math.floor(
        this.config.img_width / Math.pow(2, this.config.max_zoom - zoom)
      ),
      Math.floor(
        this.config.img_height / Math.pow(2, this.config.max_zoom - zoom)
      ),
    ];
  }

  dofusToLeafletCoords(dofusX, dofusY) {
    // 1 unit Dofus = 1 width or 1 height of a map in px
    // 1 unit Leaflet = 1px * 2^zoom
    const leafletX =
      dofusX * (this.config.img_width / Math.pow(2, this.config.max_zoom));
    const leafletY =
      -dofusY * (this.config.img_height / Math.pow(2, this.config.max_zoom));

    return [leafletY, leafletX];
  }

  leafletToDofusCoords(leafletY, leafletX) {
    // 1 unit Dofus = 1 width or 1 height of a map in px
    // 1 unit Leaflet = 1px * 2^zoom
    const dofusX =
      leafletX / (this.config.img_width / Math.pow(2, this.config.max_zoom));
    const dofusY =
      -leafletY / (this.config.img_height / Math.pow(2, this.config.max_zoom));

    return [dofusX, dofusY];
  }

  clickOnMap(e) {
    const [x, y] = this.leafletToDofusCoords(e.latlng.lat, e.latlng.lng);
    this.posXValue = Math.floor(x);
    this.posYValue = Math.floor(y);
    const latlng = this.dofusToLeafletCoords(
      this.posXValue + 0.5,
      this.posYValue + 0.5
    );
    this.inputTarget.value = `${Math.floor(x)}:${Math.floor(y)}`;
    if (!this.selectionMarker) {
      this.selectionMarker = L.marker(latlng, {
        icon: mapIcons.moon,
      });
      this.selectionMarker.addTo(this.map);
    } else {
      this.selectionMarker.setLatLng(latlng);
    }
    this.inputTarget.dispatchEvent(new Event("change"));
  }
}
