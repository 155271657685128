import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["image", "form", "submit"];

  connect() {
    if (this.isLoaded) {
      this.imageReady();
    }
  }

  imageReady() {
    if (!this.processing) {
      this.processing = true;

      this.imageTarget.classList.remove("loading");
      this.formTarget.classList.remove("loading");
    }
  }

  get isLoaded() {
    return this.hasImageTarget && this.imageTarget.complete;
  }

  positionSelected() {
    this.submitTarget.value = "GUESS";
    this.submitTarget.classList.remove("disabled");
  }
}
