import { Controller } from "stimulus";
import L from "leaflet";

L.CanvasMarker = L.Layer.extend({
  options: {
    interactive: true,
  },
  beforeAdd: function (map) {
    this._map = map;
    this._renderer = map.getRenderer(this);
  },
  onAdd: function () {
    this._renderer._initPath(this);
    this._reset();
    this._renderer._addPath(this);
  },
  onRemove: function () {
    this._renderer._removePath(this);
  },
  _reset: function () {
    this._project(), this._update();
  },
});

L.ImgCanvasMarker = L.CanvasMarker.extend({
  options: {
    img: {},
    qnt: 1,
  },
  initialize: function (a, b) {
    L.setOptions(this, b);
    this._latlng = L.latLng(a);
    this._img = this.options.img;
    this._width = this.options.img.width;
    this._height = this.options.img.height;
    this._quantity = this.options.qnt;
  },
  _project: function () {
    (this._point = this._map.latLngToLayerPoint(this._latlng)),
      this._updateBounds();
  },
  _updateBounds: function () {
    const p = [~~(this._width / 2), ~~(this._height / 2)];
    this._pxBounds = new L.Bounds(this._point.subtract(p), this._point.add(p));
  },
  _update: function () {
    this._map && this._updatePath();
  },
  _updatePath: function () {
    this._size =
      ((this.options.height * Math.pow(2, this._map.getZoom())) /
        Math.pow(2, this._map.getMaxZoom())) *
      0.9;
    this._renderer._updateImg(this);
  },
  _empty: function () {
    return this._latlng && !this._renderer._bounds.intersects(this._pxBounds);
  },
});

L.imgCanvasMarker = function (a, b) {
  return new L.ImgCanvasMarker(a, b);
};

L.ImgCanvasMarker.prototype._containsPoint = function (a) {
  const radius = (this._size / 2) * 1.2;
  return a.distanceTo(this._point) <= radius;
};

// CANVAS

const customCanvasBuilder = L.Canvas.extend({
  _updateImg: function (m) {
    if (this._drawing && !m._empty()) {
      const ctx = this._ctx;
      const pxBoundsX = m._pxBounds.min.x;
      const pxBoundsY = m._pxBounds.min.y;
      const MAX_QUANTITY = 8;
      const fraction = m._quantity / MAX_QUANTITY;

      let color = 110 - 110 * fraction;
      let light = 50;

      if (color < 0) {
        color = 0;
        light -= (30 / 40) * m._quantity;
        if (light < 20) light = 20;
      }

      ctx.strokeStyle = "black";
      ctx.lineWidth = 0.2;
      ctx.beginPath();
      ctx.arc(
        m._width / 2 + pxBoundsX,
        m._width / 2 + pxBoundsY,
        m._size / 2,
        0,
        2 * Math.PI
      );
      ctx.closePath();
      ctx.fillStyle = `hsl(${color}, 80%, ${light}%)`;
      ctx.fill();
      ctx.stroke();

      if (m._size >= 6) {
        ctx.drawImage(
          m.options.img,
          (m._width - m._size) / 2 + pxBoundsX,
          (m._width - m._size) / 2 + pxBoundsY,
          m._size,
          m._size
        );
      }

      if (m._size >= 10) {
        ctx.font = `${m._size * 0.3}pt Roboto`;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "white";

        ctx.strokeStyle = "black";
        ctx.lineWidth = m._size / 10;

        ctx.strokeText(
          m._quantity,
          m._width / 2 + pxBoundsX,
          m._width / 2 + pxBoundsY
        );
        ctx.fillText(
          m._quantity,
          m._width / 2 + pxBoundsX,
          m._width / 2 + pxBoundsY
        );
      }
    }
  },
});

// CONTROLLER
export default class extends Controller {
  static values = {
    defaultImg: String,
    itemId: String,
  };

  connect() {
    this.mapController = this.application.getControllerForElementAndIdentifier(
      this.element,
      "map"
    );
    this.map = this.mapController.map;
    this.map.createPane(
      "interactiveitemsPane",
      this.map.getPane("overlayPane")
    ).style["z-index"] = 500;

    this.selectedItems = {};
    this.items = {};
    this.maps = {};
    this.layerGroup = L.layerGroup().addTo(this.map);
    this.defaultImg = new Image(30, 30);
    this.defaultImg.src = this.defaultImgValue;
    if (this.itemIdValue) {
      this.addInterativeItemById(this.itemIdValue);
    } else {
      this.setupControlPanel();
    }
    this.canvasRenderer = new customCanvasBuilder({
      padding: 1,
      pane: "interactiveitemsPane",
    }).addTo(this.map);
  }

  setupControlPanel() {
    // this.map.zoomControl.setPosition("bottomright");

    L.Control.InteractiveItem = L.Control.extend({
      stopClick(event) {
        event.stopPropagation();

        // TODO: rewrite that - handle the click outside when clicking on another control
        document.body.parentNode.dispatchEvent(
          new CustomEvent("click-custom", { detail: event.target })
        );
      },
      onAdd: function (map) {
        var div = L.DomUtil.create("div");
        L.DomEvent.addListener(div, "dblclick", this.stopClick.bind(this));
        L.DomEvent.addListener(div, "click", this.stopClick.bind(this));
        L.DomEvent.addListener(div, "mousemove", L.DomEvent.stop);
        L.DomEvent.addListener(div, "mousewheel", L.DomEvent.stop);

        div.classList.add("leaflet-bar");
        div.id = this.options.id;

        return div;
      },
    });

    new L.Control.InteractiveItem({
      id: "directions-control",
      position: "topleft",
    }).addTo(this.map);

    new L.Control.InteractiveItem({
      id: "jobs-control",
      position: "topleft",
    }).addTo(this.map);

    const href = "/fr/encyclopedia/jobs/map_controls";
    fetch(href, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html));
  }

  addInterativeItem(event) {
    event.preventDefault();

    this.addInterativeItemById(event.detail.targetId);
  }

  addInterativeItemById(itemId) {
    if (this.selectedItems[itemId]) {
      this.selectedItems[itemId] = false;

      Object.values(this.maps).forEach((map) => {
        if (map.items[itemId]) {
          delete map.items[itemId];
          map.layer.remove();
          if (Object.keys(map.items).length) this.renderInteractives(map);
        }
      });
      this.dispatchSelection(itemId);
    } else {
      this.selectedItems[itemId] = true;

      if (this.items[itemId]) {
        this.displayOnMap(itemId);
        this.dispatchSelection(itemId, this.items[itemId], true);
      } else {
        fetch(`/fr/encyclopedia/items/${itemId}/interactives.json`)
          .then((response) => response.json())
          .then((data) => {
            this.items[itemId] = data;
            this.displayOnMap(itemId);
            this.dispatchSelection(itemId, data, true);
          });
      }
    }
  }

  dispatchSelection(itemId, itemData = undefined, selected = false) {
    this.element.dispatchEvent(
      new CustomEvent("interactive-item-selection", {
        detail: { itemId, itemData, selected },
      })
    );
  }

  worldChanged() {
    Object.values(this.maps).forEach((map) => {
      if (map.layer) map.layer.remove();
    });
    this.maps = {};
    Object.keys(this.selectedItems).forEach((itemId) =>
      this.displayOnMap(itemId)
    );
  }

  renderInteractives(map) {
    const items = Object.values(map.items);
    const markerPosition = this.mapController.dofusToLeafletCoords(
      map.x + 0.5,
      map.y + 0.5
    );

    const img = new Image(30, 30);
    img.src = items[0].img;

    if (map.layer) map.layer.remove();

    map.layer = L.imgCanvasMarker(markerPosition, {
      renderer: this.canvasRenderer,
      img: items.length > 1 ? this.defaultImg : img,
      height: this.mapController.config.img_height,
      qnt: items.reduce((res, item) => res + item.qnt, 0),
      pane: "interactiveitemsPane",
    }).addTo(this.map);

    const tooltipHtml = items
      .map(
        (item) =>
          `<strong class="d-flex flex-row align-items-center no-margin"><span class="game-img-container img-sm me-1"><img src="${item.img}" class="img-100"></span>${item.qnt} ${item.name}</strong>`
      )
      .join("");
    map.layer.bindTooltip(
      `<div class="d-flex flex-column">${tooltipHtml}<strong class="text-center no-margin">[${map.x}, ${map.y}]</strong></div>`,
      {
        sticky: true,
      }
    );
  }

  displayOnMap(itemId) {
    if (!this.selectedItems[itemId] || !this.items[itemId]) return;

    const itemData = this.items[itemId];

    itemData.maps
      .filter((map) => map.w === this.mapController.config.id)
      .forEach((map) => {
        const mapKey = `${map.x},${map.y}`;
        const item = {
          id: itemId,
          img: itemData.img,
          name: itemData.name,
          qnt: map.n,
        };
        if (this.maps[mapKey]) {
          this.maps[mapKey].items[itemId] = item;
        } else {
          this.maps[mapKey] = {
            x: map.x,
            y: map.y,
            items: { [itemId]: item },
          };
        }
      });

    itemData.maps
      .filter((map) => map.w === this.mapController.config.id)
      .forEach((map) => {
        const mapKey = `${map.x},${map.y}`;

        this.renderInteractives(this.maps[mapKey]);
      });
  }
}
