// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "bootstrap";

import Rails from "@rails/ujs";
// import * as ActiveStorage from "@rails/activestorage";
import "@hotwired/turbo";
import debounced from "debounced";
// import "channels";

Turbo.setProgressBarDelay(200);

Rails.start();
// ActiveStorage.start();
debounced.initialize();

import "controllers";

document.addEventListener("turbo:load", function (event) {
  gtag("config", "G-T8E2BVG1HF", { page_location: event.detail.url });
});
