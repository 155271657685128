import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button"];

  connect() {
    this.mapController = this.application.getControllerForElementAndIdentifier(
      this.element,
      "map"
    );
    this.map = this.mapController.map;

    this.applyLock();
  }

  toggleLock() {
    this.buttonTarget.classList.toggle("locked");
    this.applyLock();
  }

  applyLock() {
    if (
      this.hasButtonTarget &&
      this.buttonTarget.classList.contains("locked")
    ) {
      this.map.dragging.disable();
      this.map.touchZoom.disable();
      this.map.doubleClickZoom.disable();
      this.map.scrollWheelZoom.disable();
      this.map.boxZoom.disable();
      this.map.keyboard.disable();
      if (this.map.tap) this.map.tap.disable();
    } else {
      this.map.dragging.enable();
      this.map.touchZoom.enable();
      this.map.doubleClickZoom.enable();
      this.map.scrollWheelZoom.enable();
      this.map.boxZoom.enable();
      this.map.keyboard.enable();
      if (this.map.tap) this.map.tap.enable();
    }
  }
}
