import { Controller } from "stimulus";
import { computePosition, flip, shift, offset } from "@floating-ui/dom";

export default class extends Controller {
  static targets = ["reference", "toggled"];
  static classes = ["toggle"];
  static values = {
    default: Boolean,
    removeOnClickOutside: Boolean,
    placement: String,
  };

  toggle(event) {
    if (!this.defaultValue) event.preventDefault();

    this.toggledTargets.forEach((toggled) =>
      toggled.classList.toggle(this.toggleClass)
    );
  }

  popper(event) {
    if (!this.defaultValue) event.preventDefault();

    computePosition(
      this.hasReferenceTarget ? this.referenceTarget : this.element,
      this.toggledTarget,
      {
        placement: this.placementValue || "bottom",
        middleware: [offset(8), flip(), shift({ padding: 20 })],
      }
    ).then(({ x, y }) => {
      Object.assign(this.toggledTarget.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  }

  toggleOnClickOutside(event) {
    event.preventDefault();

    document.body.parentNode.addEventListener(
      "click-custom",
      this.customHandler.bind(this)
    );
    document.body.parentNode.addEventListener("click", this.handler.bind(this));
  }

  customHandler(event) {
    this.handler({ target: event.detail });
  }

  handler(event) {
    // If click is outside the element to toggle
    if (
      !this.toggledTarget.contains(event.target) &&
      !this.element.contains(event.target)
    ) {
      if (this.removeOnClickOutsideValue) {
        this.toggledTarget.classList.remove(this.toggleClass);
      } else {
        this.toggledTarget.classList.add(this.toggleClass);
      }
    }
  }
}
