import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "input",
    "field",
    "results",
    "selectedList",
    "selected",
    "inspectorName",
    "inspectorImg",
    "firstHover",
  ];

  static values = {
    url: String,
    default: Boolean,
  };

  open() {
    this.fieldTarget.focus();
  }

  multiSelected(event) {
    const selectableElement = this.selected(event);

    selectableElement.classList.toggle("selected");
  }

  selected(event) {
    if (!this.defaultValue) event.preventDefault();

    const selectableElement = event.target.closest("[data-action]");

    if (this.hasInputTarget) {
      if (this.hasSelectedTarget) {
        this.inputTarget.value = this.selectedTargets
          .map((element) => element.getAttribute("data-target-id"))
          .join(",");
      } else {
        this.inputTarget.value =
          selectableElement.getAttribute("data-target-id");
      }
    } else {
      this.element.dispatchEvent(
        new CustomEvent("selected", {
          detail: selectableElement.dataset,
        })
      );

      // legacy event
      const customEvent = new CustomEvent("add-reference", {
        detail: {
          reference_id: selectableElement.getAttribute(
            "data-target-reference-id"
          ),
        },
      });
      window.dispatchEvent(customEvent);
    }

    return selectableElement;
  }

  addSelected(event) {
    event.preventDefault();

    const selectedNode = event.target.cloneNode(true);
    selectedNode.setAttribute(
      "data-action",
      `click->${this.identifier}#removeSelected`
    );
    selectedNode.setAttribute("data-search-popover-target", "selected");
    this.selectedListTarget.appendChild(selectedNode);
  }

  removeSelected(event) {
    event.preventDefault();

    event.target.remove();
  }

  search() {
    this.resultsTarget.src = this.searchUrl();
  }

  searchUrl() {
    const url = new URL(
      this.urlValue,
      `${window.location.protocol}//${window.location.host}`
    );

    this.fieldTargets.forEach((field) => {
      url.searchParams.append(field.name, field.value);
    });

    return url.toString();
  }

  updateInspector(event) {
    event.preventDefault();

    if (!this.hoveredElement && this.hasFirstHoverTarget) {
      this.hoveredElement = this.firstHoverTarget;
    }

    const selectableElement = event.target.closest("[data-action]");
    if (selectableElement && this.hoveredElement !== selectableElement) {
      this.hoveredElement.classList.remove("popover-search-hovered");

      this.hoveredElement = selectableElement;

      this.hoveredElement.classList.add("popover-search-hovered");

      if (this.hasInspectorNameTarget) {
        this.inspectorNameTarget.textContent =
          this.hoveredElement.getAttribute("data-target-name");
      }

      if (this.hasInspectorImgTarget) {
        const imgElement = this.hoveredElement.querySelector("img");
        if (imgElement) {
          this.inspectorImgTarget.src = imgElement.src;
        } else if (this.hoveredElement.src) {
          this.inspectorImgTarget.src = this.hoveredElement.src;
        }
      }
    }
  }
}
