import { Controller } from "stimulus";
import L from "leaflet";
import { mapIcons } from "./map_controller";

export default class extends Controller {
  static targets = [];

  connect() {
    this.mapController = this.application.getControllerForElementAndIdentifier(
      this.element,
      "map"
    );
    this.map = this.mapController.map;
    this.map.createPane(
      "directionsPane",
      this.map.getPane("overlayPane")
    ).style["z-index"] = 501;
  }

  reset() {
    if (this.markers) this.markers.clearLayers();
  }

  drawDirections(event) {
    const { data, selectedId } = event.detail;
    if (!this.markers) {
      this.markers = L.featureGroup().addTo(this.map);
    }
    this.markers.clearLayers();
    data.directions
      .sort((a, b) => {
        if (a.id === selectedId) return 1;
        if (b.id === selectedId) return -1;
        return a.map_relations.length < b.map_relations.length;
      })
      .forEach((direction) => {
        const worldIds = [direction.world_map_id];
        if (this.mapController.config.id === worldIds[0]) {
          this.drawMarker(
            direction.map_relations[0].x,
            direction.map_relations[0].y,
            direction.hint_icon
          );
        }

        let latLngs = [];
        const currentGroup = L.featureGroup();
        let to_hint;
        const color = direction.id === selectedId ? "#5B65EA" : "#72767d";
        const shadowColor = direction.id === selectedId ? "#1722b5" : "#4f545c";
        direction.map_relations
          .concat(data.destination)
          .forEach((map_relation) => {
            if (this.mapController.config.id === worldIds[0]) {
              latLngs.push(
                this.mapController.dofusToLeafletCoords(
                  map_relation.x + 0.5,
                  map_relation.y + 0.5
                )
              );

              if (to_hint) {
                this.drawMarker(
                  map_relation.x,
                  map_relation.y,
                  to_hint,
                  worldIds[1] !== worldIds[0] ? worldIds[1] : undefined
                );
                this.drawLine(latLngs, currentGroup, color, shadowColor, true);
                latLngs = latLngs.slice(-1);
                to_hint = null;
              }

              if (map_relation.from_icon) {
                to_hint = map_relation.to_icon;
                const newWorldId =
                  map_relation.to_world_map_id &&
                  map_relation.to_world_map_id !== worldIds[0]
                    ? map_relation.to_world_map_id
                    : null;
                this.drawMarker(
                  map_relation.x,
                  map_relation.y,
                  map_relation.from_icon,
                  newWorldId
                );
                if (latLngs.length > 0) {
                  this.drawLine(latLngs, currentGroup, color, shadowColor);
                  latLngs = latLngs.slice(-1);
                }
              }
            }

            if (map_relation.to_world_map_id) {
              worldIds.unshift(map_relation.to_world_map_id);
              to_hint = map_relation.to_icon;
            } else worldIds.unshift(worldIds[0]);
          });
        this.drawLine(latLngs, currentGroup, color, shadowColor);
        if (direction.id === selectedId) {
          const desktop = window.innerWidth > 600;
          const options = {
            paddingTopLeft: [desktop ? 400 : 0, 0],
            paddingBottomRight: [0, desktop ? 0 : window.innerHeight / 2],
            duration: 0.25,
          };
          this.map.fitBounds(currentGroup.getBounds().pad(0.2), options);
          setTimeout(() => {
            this.map.fitBounds(currentGroup.getBounds().pad(0.2), options);
          }, 500);
        }
        if (
          !this.destinationMarker &&
          this.mapController.config.id === worldIds[0]
        )
          this.destinationMarker = this.drawMarker(
            data.destination.x,
            data.destination.y
          );
      });
  }

  drawMarker(posX, posY, iconUrl, directionWorldId) {
    let icon;
    if (iconUrl)
      icon = L.icon({
        iconUrl,
        iconSize: [30, 30],
        iconAnchor: [15, 20],
      });
    else icon = mapIcons.destination;

    const markerPosition = this.mapController.dofusToLeafletCoords(
      posX + 0.5,
      posY + 0.5
    );

    this.markers.addLayer(
      L.marker(markerPosition, { icon: icon })
        .addTo(this.map)
        .on("click", this.clickedMarker.bind(this, directionWorldId))
    );
  }

  clickedMarker(worldId, e) {
    if (!worldId) return;

    this.element.dispatchEvent(
      new CustomEvent("request-world-switch", {
        detail: { id: worldId, keepPanel: true },
      })
    );
  }

  drawLine(latLngs, currentGroup, color, shadowColor, dash = false) {
    const dashPattern = "2, 10";
    // BORDER
    const line = L.polyline(latLngs, {
      color: shadowColor,
      weight: 8,
      dashArray: dash ? dashPattern : null,
      pane: "directionsPane",
    }).addTo(this.map);

    this.markers.addLayer(line);
    currentGroup.addLayer(line);

    // LINE OVER BORDER
    this.markers.addLayer(
      L.polyline(latLngs, {
        color: color,
        weight: 4,
        dashArray: dash ? dashPattern : null,
        pane: "directionsPane",
      }).addTo(this.map)
    );
  }
}
