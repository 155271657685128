import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  initialize() {
    this.mapController = this.application.getControllerForElementAndIdentifier(
      this.element,
      "map"
    );
    this.map = this.mapController.map;
    const pane = this.map.createPane(
      "subAreaPane",
      this.map.getPane("overlayPane")
    );
    pane.style["z-index"] = 450;
    this.layers = L.featureGroup().addTo(this.map);

    fetch(`/fr/encyclopedia/searchs/sub_areas.json`)
      .then((response) => response.json())
      .then(this.loadSubAreas.bind(this));
  }

  loadSubAreas(data) {
    this.maps = {};
    this.subAreas = data.sub_areas.reduce((res, subArea) => {
      res[subArea.id] = {
        ...subArea,
        maps: subArea.maps.split(",").map((map) => {
          const mapCoords = map[0] === "~" ? map.slice(1) : map;
          const key = `${subArea.world_map_id}-${mapCoords}`;
          if (map[0] !== "~" || !this.maps[key]) this.maps[key] = subArea.id;
          const [x, y] = mapCoords.split(":");
          return { x: parseInt(x), y: parseInt(y) };
        }),
      };
      return res;
    }, {});

    this.map.on("mousemove", this.mouseMoveOnMap.bind(this));
    this.map.on("mouseout", this.mouseOutOfMap.bind(this));
    this.map.on("dragstart", this.startDragMap.bind(this));
    this.map.on("moveend", this.stopDragMap.bind(this));
  }

  startDragMap() {
    this.pause = true;
  }

  stopDragMap() {
    this.pause = false;
  }

  mouseMoveOnMap(e) {
    if (this.pause) return;

    const coords = this.mapController.leafletToDofusCoords(
      e.latlng.lat,
      e.latlng.lng
    );
    const subAreaId =
      this.maps[
        `${this.mapController.config.id}-${Math.floor(coords[0])}:${Math.floor(
          coords[1]
        )}`
      ];

    if (this.currentSubAreaId != subAreaId) {
      this.currentSubAreaId = subAreaId;

      this.layers.clearLayers();
      if (!subAreaId) {
        this.dispatchSubAreaChanged();
        return;
      }

      const subArea = this.subAreas[subAreaId];
      this.dispatchSubAreaChanged(subArea.name);
      subArea.maps.forEach((map) => {
        const topLeftBound = this.mapController.dofusToLeafletCoords(
          map.x,
          map.y
        );
        const bottomRightBound = this.mapController.dofusToLeafletCoords(
          map.x + 1,
          map.y + 1
        );
        this.layers.addLayer(
          L.rectangle([topLeftBound, bottomRightBound], {
            color: "#5B65EA",
            weight: 0,
            fillOpacity: 0.5,
            pane: "subAreaPane",
          })
        );
      });
    }
  }

  mouseOutOfMap(e) {
    this.currentSubAreaId = null;
    this.layers.clearLayers();
    this.dispatchSubAreaChanged();
  }

  dispatchSubAreaChanged(name) {
    this.element.dispatchEvent(
      new CustomEvent("sub-area-changed", {
        detail: {
          name,
        },
        bubbles: true,
      })
    );
  }
}
